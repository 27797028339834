import React from "react";

export default function Error({message}) {
  return (
    <div
      className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full"
      role="alert"
    >
      <span>{message}</span>
    </div>
  );
}

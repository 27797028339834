import React, { useContext, useEffect, useState } from "react";

const DashboardContext = React.createContext();

export function useDashboard() {
  return useContext(DashboardContext);
}

export  function DashboardProvider({ children }) {
  const [dashboard, setDashboard] = useState('a');
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState(window.localStorage.getItem('theme'));
  const [currentSecretId, setCurrentSecretId] = useState("");
  const [secretIdList, setSecretIdList] = useState();
  const [companyName, setCompanyName] = useState("Nome da Empresa");
  useEffect(() => {
    const theme = window.localStorage.getItem('theme');

    if(!theme)
      window.localStorage.setItem('theme', 'light');
    else
      window.localStorage.setItem('theme', theme);
  }, [theme]);

  const handleTheme = () =>{
    theme === "dark" ? setTheme('light') : setTheme('dark');
    theme === "dark" ? window.localStorage.setItem('theme', 'light') : window.localStorage.setItem('theme', 'dark');
  }

  const value = {
    dashboard,
    setDashboard,
    theme,
    setTheme,
    handleTheme,
    currentSecretId,
     setCurrentSecretId,
      secretIdList, 
      setSecretIdList,
      companyName,
      setCompanyName,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
}

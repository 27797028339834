import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

import FormControl from "@material-ui/core/FormControl";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Mail from "@material-ui/icons/Mail";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import PhoneIcon from '@material-ui/icons/Phone'

const InputDefault = ({ inputValue, inputType, id, value, onChange, type, tabIndex}) => {
  const [values, setValues] = useState({
    showPassword: false,
  });



  const handleClickShowPassword = () => {
    if (inputType === "password")
      setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function renderSwitch(inputType) {
    switch (inputType) {
      case "mail":
        return <Mail />;

      case "phone":
        return <PhoneIcon/>

      case "password":
        return values.showPassword ? <VisibilityOff /> : <Visibility />;

      default:
        return <TextFieldsIcon />;
    }
  }

  return (
    <div>
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-adornment-password">
          {inputValue}
        </InputLabel>

        <OutlinedInput
          className="bg-white"
          id={id}
          type={values.showPassword || inputType !== "password" ? type : "password"}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disableUnderline={true}
          label={inputValue}
          inputProps={{ tabIndex: tabIndex }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {renderSwitch(inputType)}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
};

export default InputDefault;

import React, { useState } from "react";

import illustrationImg from "../assets/login-illustration.svg";
import Button from "../components/button";
import Input from "../components/inputs/inputs";
import { useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { FirebaseErrorMessages } from "../utils/FirebaseErrorMessages";
import Error from "../components/Error";

export default function RegisterB2B() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [error, setError] = useState();
  const [showEmailMessage, setShowEmailMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const { signUp } = useAuth();

  const history = useHistory();

  const handleLoginButton = () => {
    history.push("/business/login");
  };

  const handleRegisterButton = async () => {
    setLoading(true);
    setError("");
    if (password !== passwordConfirm) {
      setError("A confirmação de senha não confere!");
      setLoading(false);
      return;
    }

    try {
      await signUp(email, password);
      setShowEmailMessage(true);
    } catch (error) {
      setError(FirebaseErrorMessages[error.code] || error.message);
    }
    setLoading(false);
  };

  return (
    <section className="flex h-screen items-center">
      <div className="container px-4 pt-20 mx-auto flex items-center flex-wrap md:flex-nowrap gap-12">
        <div className="lg:w-3/5 md:w-1/2 h-full">
          <h1 className="hidden md:block text-eurekka-preto font-bold text-2xl lg:text-2xl mb-3 ml-10 md:pr-16 lg:pr-0 pr-0">
            Dashboard Eurekka Empresa<br/>
            <span className="text-2xl lg:text-5xl">Acompanhe a saúde mental<br/> da sua equipe</span>
          </h1>
          <div className="hidden md:flex justify-center">
            <img
              className="object-fill  w-6/12 h-6/12"
              src={illustrationImg}
              alt=""
            />
          </div>
        </div>
        <div className="lg:w-2/6 md:w-1/2 p-6 flex flex-col md:ml-auto w-full bg-gray-50 rounded">
          {showEmailMessage === false ? (
            <>
              <h1 className="text-eurekka-preto font-bold text-lg mb-8">
                Registre-se
              </h1>
              <div className="flex flex-col gap-4">
                <Input
                  inputValue="E-Mail"
                  inputType="mail"
                  value={email}
                  tabIndex={1}
                  onChange={setEmail}
                />
                <Input
                  inputValue="Senha"
                  inputType="password"
                  value={password}
                  onChange={setPassword}
                  tabIndex={2}
                />
                <Input
                  inputValue="Confirmação de Senha"
                  inputType="password"
                  value={passwordConfirm}
                  onChange={setPasswordConfirm}
                  tabIndex={3}
                />
              </div>
              <div className="flex flex-wrap gap-4 mt-8">
                {error && <Error message={error} />}
                <Button
                  title="Registrar"
                  onClick={handleRegisterButton}
                  className="w-6/12 mt-8 mx-auto"
                  disabled={loading}
                  tabIndex={4}
                />
              </div>
              <p className="text-sm text-gray-500 mt-8 text-center">
                Já possui uma conta?{" "}
                <span
                  className="text-eurekka-azul cursor-pointer"
                  onClick={handleLoginButton}
                >
                  Faça login
                </span>
              </p>
            </>
          ) : (
            <div className="text-lg">
              {" "}
              É necessario confirmar o e-mail cadastrado para ter acesso a
              plataforma. Por favor verifique sua caixa de entrada para
              continuar{" "}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

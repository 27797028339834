import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Robozinha from "../assets/build.png";
import Error from "../components/Error";
import { useAuth } from "../context/AuthContext";
const Warning = () => {
  const { emailVerification } = useAuth();
  const [email, setEmail] = useState(false);
  const [error, setError] = useState("");

  async function handleButton() {
    try {
      setEmail(true);
      await emailVerification();
    } catch (error) {
      setTimeout(async () => {
        try {
          await emailVerification();
        } catch (error) {
          setError("Aconteceu algo de errado, tente novamente mais tarde");
        }
      }, 5000);
    }
  }

  return (
    <>
      <section className="flex h-screen items-center">
        <div className="container px-4 py-6 md:py-24 mx-auto flex items-center flex-wrap-reverse">
          <div className="lg:w-3/5 md:w-1/2 h-full flex flex-col">
            <h1 className="text-eurekka-preto text-center font-bold text-xl md:text-3xl lg:text-5xl mb-3">
              Ops, parece que seu e-mail ainda não foi confirmado!
            </h1>
            <h4 className="text-eurekka-preto justify-center align-middle text-center mx-auto mt-3 md:mt-16 w-8/12 text-md md:text-xl lg:text-3xl font-small mb-3">
              Verifique sua caixa de entrada de e-mails e depois volte aqui!
            </h4>
            {!error ? (
              email ? (
                <div className="text-lg text-center pt-4">
                  Um novo e-mail foi enviado!
                </div>
              ) : (
                <button
                  className="text-blue-400 pt-4"
                  disabled={email}
                  onClick={() => handleButton()}
                >
                  Reenviar e-mail de confirmação
                </button>
              )
            ) : (
              <div className="mx-auto text-sm">
                {error && <Error message={error} />}
              </div>
            )}
          </div>
          <div className="lg:w-2/6 md:w-1/2 p-2 pt-12 flex flex-col md:ml-auto w-full  rounded">
            <div className="flex justify-center mt-4">
              <img
                className="object-fill  w-12/12 h-12/12"
                src={Robozinha}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Warning;

import React from "react";
import { Redirect, Route } from "react-router";
import { useAuth } from "../context/AuthContext";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser, loading } = useAuth();

  return (
    <div>
      <Route
        {...rest}
        render={(props) => {
          return loading ? (
            ""
          ) : currentUser ? (
            <Component {...props} />
          ) : (
            <Redirect to="/business/login" />
          );
        }}
      ></Route>
    </div>
  );
}

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/nav_bar";
import NavbarDash from "./components/navbar_dash";
import BusinessRegister from "./pages/business_register";
import Home from "./pages/home";
import Dashboard from "./pages/dashboard";
import LoginB2B from "./pages/login_b2b";
import Build from "./pages/build";
import Warning from "./pages/warning_email";
import LoginB2C from "./pages/login_b2c";
import RegisterB2B from "./pages/register_b2b";
import RegisterB2C from "./pages/register_b2c";
import { AuthProvider } from "./context/AuthContext";
import { DashboardProvider } from "./context/DashboardContext";
import PrivateRoute from "./components/PrivateRoute";

export default function App() {
  let ok = true;

  return (
    <DashboardProvider>
    <AuthProvider>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={LoginB2B} />
          {/* <Route path="/customer/login" component={LoginB2C} /> */}
          {/* <Route path="/customer/register" component={RegisterB2C} /> */}
          <Route path="/business/login" component={LoginB2B} />
          <Route path="/business/register" component={RegisterB2B} />
          {/* <Route path="/business_register" component={BusinessRegister} /> */}
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/build" component={Build} />
          <PrivateRoute path="/warning" component={Warning} />
          {/* <Route path="/build" component={Dashboard} /> */}
        </Switch>
      </Router>
    </AuthProvider>
    </DashboardProvider>

  );
}

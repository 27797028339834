import React from "react";

export default function Button({ title, onClick, className, disabled, tabIndex }) {
  return (
    <button
      className={`bg-eurekka-amarelo hover:bg-yellow-300 text-black font-bold py-2 px-4 rounded ${className}`}
      onClick={onClick}
      disabled={disabled}
      tabIndex={tabIndex}
    >
      {title}
    </button>
  );
}

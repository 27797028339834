import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { auth } from "../services/firebase";

export const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  async function signUp(email, password) {
    const response = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const actionCodeSettings = {
      url: process.env.REACT_APP_BISTRO_FRONT_URL + "/business/login",
    };
    sendEmailVerification(response.user, actionCodeSettings);
    return response;
  }

  async function emailVerification() {
    if (!currentUser.emailVerification) {
      const actionCodeSettings = {
        url: process.env.REACT_APP_BISTRO_FRONT_URL + "/",
      };
      await sendEmailVerification(currentUser, actionCodeSettings);
    }
  }

  async function signIn(email, password) {
    const response = await signInWithEmailAndPassword(auth, email, password);
    return response.user;
  }

  async function logout() {
    await signOut(auth);
  }

  async function forgotPassword(email) {
    sendPasswordResetEmail(auth, email);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signUp,
    signIn,
    loading,
    logout,
    forgotPassword,
    emailVerification,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

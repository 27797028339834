import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../context/AuthContext";
import { useDashboard } from "../context/DashboardContext";
import "../styles/darkloading.css";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [iframeUrl, setIframeUrl] = useState("");

  const { dashboard, theme } = useDashboard();
  const {
    currentSecretId,
    setCurrentSecretId,
    secretIdList,
    setSecretIdList,
    companyName,
    setCompanyName,
  } = useDashboard();

  const { currentUser } = useAuth();

  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (currentUser.emailVerified === false) {
        history.push("/warning");
        return;
      }

      setLoading(true);
      const url = process.env.REACT_APP_BISTRO_API_URL + "/metabase/dashboard";
      const options = {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: currentUser.email,
          dashboard: dashboard,
          secretId: currentSecretId,
        }),
      };

      let result;
      try {
        const data = await fetch(url, options);
        result = await data.json();
      } catch (error) {
        history.push("/build");
        return;
      }

      // Se vier qualquer mensagem de erro, o usuário é direcionado para a página de "estamos construindo essa página"
      if (result.message) {
        history.push("/build");
      }

      // Se a lista de secretId já estiver preenchida, não preenche com nada.
      if (!secretIdList) {
        setSecretIdList(result.companies);
      }
      setCurrentSecretId(result.secretId);

      setCompanyName(result.companies[0].name);

      const token = result.token;

      let iframeThemedUrl;
      theme == "light"
        ? (iframeThemedUrl =
            process.env.REACT_APP_METABASE_URL + "/embed/dashboard/" + token)
        : (iframeThemedUrl =
            process.env.REACT_APP_METABASE_URL +
            "/embed/dashboard/" +
            token +
            "#theme=night&bordered=true&titled=true");

      setIframeUrl(iframeThemedUrl);
      setLoading(false);
    })();
  }, [dashboard, theme, currentSecretId]);

  useEffect(() => {
    const page = document.querySelector(".h-screen");
    if (theme === "light") {
      page.classList.add("bg-white");
      page.classList.remove("bg-dark-theme");
    }
    if (theme === "dark") {
      page.classList.add("bg-dark-theme");
      page.classList.remove("bg-white");
    }
  }, [theme]);

  return (
    <div className="flex h-screen">
      {loading ? (
        <div className="flex flex-grow m-auto items-center justify-center">
          <div className="spinner-box">
            <div
              className={
                "blue-orbit leo " + (theme === "dark" ? "blue-orbit-dark" : "")
              }
            ></div>

            <div
              className={
                "green-orbit leo " +
                (theme === "dark" ? "green-orbit-dark" : "")
              }
            ></div>

            <div
              className={
                "red-orbit leo " + (theme === "dark" ? "red-orbit-dark" : "")
              }
            ></div>

            <div
              className={
                "white-orbit w1 leo " +
                (theme === "dark" ? "white-orbit-dark" : "")
              }
            ></div>
            <div
              className={
                "white-orbit w2 leo " +
                (theme === "dark" ? "white-orbit-dark" : "")
              }
            ></div>
            <div
              className={
                "white-orbit w3 leo " +
                (theme === "dark" ? "white-orbit-dark" : "")
              }
            ></div>
          </div>
        </div>
      ) : (
        <div className="flex flex-grow pt-32 mx-auto justify-center">
          <iframe
            title="metabase"
            src={iframeUrl}
            width="100%"
            height="100%"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default Dashboard;

import React from "react";
import Robozinha from "../assets/build.png";
const Build = () => {
  
  return (
    <>
    

<section className="flex h-screen items-center">
<div className="container px-4 py-6 md:py-24 mx-auto flex items-center flex-wrap-reverse">
  <div className="lg:w-3/5 md:w-1/2 h-full">
      <h1 className="text-eurekka-preto text-center md:text-left font-bold text-2xl lg:text-5xl title-font mb-3 ml-10 md:pr-16 lg:pr-0 pr-0">
            Olá, eu sou a robozinha da Eurekka!
      </h1>
      <h4 className="text-eurekka-preto text-center md:text-left font-bold text-1xl lg:text-3xl font-small title-font mb-3 ml-10 md:pr-16 lg:pr-0 pr-0" >
            Ainda estou construindo essa página para você, mas em breve teremos várias coisas legais por aqui!
      </h4>
    
  </div>
  <div className="lg:w-2/6 md:w-1/2 p-2 pt-12 flex flex-col md:ml-auto w-full  rounded">
    <div className="flex justify-center mt-4">
        <img className="object-fill  w-12/12 h-12/12" src={Robozinha}  alt="" />
    </div>  
 </div>   
</div>
</section>
</>
  );
};

export default Build;

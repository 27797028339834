import { useState } from "react";

import Button from "../button";
import Input from "../inputs/inputs";

import imgX from "../../assets/x-close.svg";

import "./styles.css";
import { useAuth } from "../../context/AuthContext";

export default function ModalPasswordRecovery({ ...props }) {
  const [modalDisplay, setModalDisplay] = useState(false);
  const [blockHandle, setblockHandle] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");

  const { forgotPassword } = useAuth();

  const handleModal = (event) => {
    const modal = document.querySelector("#modal-pasword-recovery");

    // Impede o modal fechar com clique dentro
    if ((modalDisplay && event.target !== event.currentTarget) || blockHandle)
      return;

    if (modalDisplay) {
      modal.classList.remove("fadeIn");
      modal.classList.add("fadeOut");
      setblockHandle(true);
      setTimeout(() => {
        modal.style.display = "none";
        setblockHandle(false);
        setEmailSent(false);
      }, 500);
    } else {
      modal.classList.remove("fadeOut");
      modal.classList.add("fadeIn");
      modal.style.display = "flex";
    }
    setModalDisplay(!modalDisplay);
  };

  function handleSendEmail() {
    // TODO:logica de enviar email com firebase
    forgotPassword(email);
    setEmailSent(true);
  }

  return (
    <div className={props.className}>
      <button
        type="button"
        className="w-full text-sm text-blue-500 text-right"
        onClick={handleModal}
      >
        Esqueceu sua senha?
      </button>
      <div
        id="modal-pasword-recovery"
        className="fixed w-full inset-0 z-50 overflow-hidden justify-center items-center animated hidden faster bg-black bg-opacity-75"
        onClick={handleModal}
      >
        <div className="border border-black-500modal-container bg-white w-11/12 sm:w-6/12 lg:w-3/12 2xl:w-1/5 mx-auto rounded-xl shadow-lg z-50 overflow-y-auto p-4">
          <div className="flex justify-end">
            <img
              className="cursor-pointer z-50"
              onClick={handleModal}
              src={imgX}
              alt=""
            />
          </div>
          {emailSent === false ? (
            <div className="flex flex-wrap just justify-center gap-6">
              {/* content header */}
              <div className="flex justify-center items-center">
                <h2 className="font-bold text-xl text-center">
                  Redefinição de senha
                </h2>
              </div>
              {/* content body */}
              <div className="flex justify-center flex-wrap gap-6">
                <p
                  id="transition-modal-description"
                  className="text-center mx-10 w-full"
                >
                  Para recurar sua senha digite seu e-mail no campo abaixo.
                </p>
                <div className="w-full">
                  <Input
                    inputValue="E-Mail"
                    inputType="mail"
                    value={email}
                    onChange={setEmail}
                  />
                </div>
                <Button
                  className="mt-4"
                  title="Recuperar Senha"
                  onClick={handleSendEmail}
                />
              </div>
            </div>
          ) : (
            <div className="text-center text-lg font-medium m-4 mb-8">
              Enviamos um e-mail com instruções para recuperação da conta.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export const FirebaseErrorMessages = {
  "auth/invalid-email": "E-mail inválido!",
  "auth/invalid-password": "Senha deve conter mais que 6 caracteres!",
  "auth/weak-password": "Senha deve conter mais que 6 caracteres!",
  "auth/wrong-password": "E-mail ou senha incorretos!",
  "auth/user-not-found": "E-mail ou senha incorretos!",
  "auth/email-already-in-use": "Este e-mail já está sendo utilizado.",
  "auth/admin-restricted-operation": "Informe todos os dados primeiro",
  "auth/internal-error": "Informe todos os dados primeiro",
};

import React, { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import useMedia from "../utils/useMedia";
import "../styles/style.css";
import styles from "./navbar_dash.module.css";
import { useDashboard } from "../context/DashboardContext";
import CustomizedSwitches from "./switch_theme";
import { Component } from "react";
import Select from "react-select";

const NavbarDash = () => {
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const [selectOptions, setSelectOptions] = React.useState({});

  const mobile = useMedia("(max-width: 64rem)");

  const { setDashboard, theme, handleTheme } = useDashboard();
  const {
    setCurrentSecretId,
    secretIdList,
    companyName,
    setCompanyName,
  } = useDashboard();

  function handleClick(string) {
    setDashboard(string);
  }

  function handleSelect(event) {
    for (const index in secretIdList) {
      const company = secretIdList[index];
      if (event.value === company.slug) {
        setCurrentSecretId(company.secretId);
        setCompanyName(company.name);
      }
    }
  }

  const { pathname } = useLocation();
  React.useEffect(() => {
    setMobileMenu(false);
  }, [pathname]);

  useEffect(() => {
    if (secretIdList?.length > 1) {
      const selectWrapper = document.querySelector("#select-wrapper");
      selectWrapper.classList.remove("invisible");
      selectWrapper.classList.remove("hidden");
    }

    let options = [];
    for (const index in secretIdList) {
      const company = secretIdList[index];
      options.push({
        selected: "true",
        value: company.slug,
        label: company.name,
      });
    }
    setSelectOptions(options);
  }, [secretIdList]);

  return (
    <>
      <nav
        className={`${styles.navDash} flex flex-wrap flex-row justify-between items-center bg-eurekka-amarelo px-8 md:px-12 py-2`}
      >
        {mobile && (
          <button
            className={`${styles.mobileButton} ${
              mobileMenu && styles.mobileButtonActive
            }`}
            aria-label="Menu"
            onClick={() => setMobileMenu(!mobileMenu)}
          ></button>
        )}
        <div id="select-wrapper" className="invisible w-6/12 md:w-1/12">
          <Select
            placeholder={companyName}
            options={selectOptions}
            onChange={(event) => handleSelect(event)}
          />
        </div>
        <ul
          className={`${mobile ? styles.navMobile : styles.nav} ${
            mobileMenu && styles.navMobileActive
          } flex items-center`}
        >
          <li>
            <NavLink
              activeClassName={styles.active}
              to="/dashboard/overview"
              onClick={() => handleClick("a")}
            >
              Overview
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={styles.active}
              to="/dashboard/conteudos"
              onClick={() => handleClick("b")}
            >
              Conteúdos Acessados
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={styles.active}
              to="/dashboard/mapa-saude"
              onClick={() => handleClick("c")}
            >
              Mapa da Saúde Mental
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={styles.active}
              to="/dashboard/mapa-trabalho"
              onClick={() => handleClick("d")}
            >
              Mapa do Trabalho
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={styles.active}
              to="/dashboard/testes"
              onClick={() => handleClick("e")}
            >
              Testes
            </NavLink>
          </li>
        </ul>
        <CustomizedSwitches
          checked={theme === "dark" ? true : false}
          onChange={handleTheme}
        ></CustomizedSwitches>
      </nav>
    </>
  );
};
export default NavbarDash;

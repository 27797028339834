import React, { useEffect, useState } from "react";

import illustrationImg from "../assets/login-illustration.svg";
import Button from "../components/button";

import ModalPasswordRecovery from "../components/ModalPasswordRecovery/index";
import Input from "../components/inputs/inputs";
import { useHistory } from "react-router-dom";

import { useAuth } from "../context/AuthContext";
import Error from "../components/Error";
import { FirebaseErrorMessages } from "../utils/FirebaseErrorMessages";

export default function LoginB2B() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { currentUser, signIn } = useAuth();

  const history = useHistory();

  useEffect(() => {
    if (currentUser?.emailVerified) history.push("/dashboard/overview");
  }, [currentUser]);

  const handleLoginButton = async () => {
    setError("");
    if (!password || !email) {
      setError("Preencha os dados acima.");
      return;
    }

    try {
      setLoading(true);
      await signIn(email, password);
      history.push("/dashboard/overview");
    } catch (error) {
      setLoading(false);
      setError(FirebaseErrorMessages[error.code] || error.message);
    }
  };

  const handleRegisterButton = () => {
    history.push("/business/register");
  };

  return (
    <section className="flex h-screen items-center">
      <div className="container px-4 pt-20 mx-auto flex items-center flex-wrap md:flex-nowrap gap-12">
        <div className="lg:w-3/5 md:w-1/2 h-full">
          <h1 className="hidden md:block text-eurekka-preto font-bold text-xl lg:text-2xl mb-3 ml-10 md:pr-16 lg:pr-0 pr-0">
            Dashboard Eurekka Empresa<br/>
            <span className="text-2xl lg:text-5xl">Acompanhe a saúde mental<br/> da sua equipe</span>
          </h1>
          <div className="flex justify-center">
            <img
              className="object-fill w-6/12 h-6/12"
              src={illustrationImg}
              alt=""
            />
          </div>
        </div>
        <div className="lg:w-2/6 md:w-1/2 p-6 flex flex-col md:ml-auto w-full bg-gray-50 rounded">
          <h1 className="text-eurekka-preto font-bold text-lg mb-3">
            Faça seu login
          </h1>
          <div className="flex flex-col gap-4">
            <Input
              inputValue="E-Mail"
              inputType="mail"
              value={email}
              onChange={setEmail}
              tabIndex={1}
            />
            <Input
              inputValue="Senha"
              inputType="password"
              value={password}
              onChange={setPassword}
              tabIndex={2}
            />
          </div>
          <ModalPasswordRecovery />
          <div className="flex flex-wrap gap-4 mt-8">
            {error && <Error message={error} />}
            <Button
              title="Entrar"
              onClick={handleLoginButton}
              className="w-6/12 mx-auto"
              disabled={loading}
              tabIndex={3}
            />
          </div>
          <p className="text-sm text-gray-500 mt-8 text-center">
            Novo por aqui?{" "}
            <span
              className="text-eurekka-azul cursor-pointer"
              onClick={handleRegisterButton}
            >
              Registre-se
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}

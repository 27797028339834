import React from "react";
import ActiveButton from "./button";
import NavbarDash from "./navbar_dash";
import whiteLogo from "../assets/logo-eurekka-white.svg";
import "../styles/style.css";
import { useAuth } from "../context/AuthContext";
import { useHistory } from "react-router";
import { useLocation } from 'react-router-dom';

const Navbar = () => {
  const { currentUser, logout } = useAuth();

  const history = useHistory();
  const { pathname } = useLocation();

  const handleLogout = async () => {
    await logout();

    history.push("/business/login");
  };

  return (
    <section className="container">
      <header className="body-font absolute w-full">
        <div className="mx-auto flex flex-wrap px-8 py-4 flex-row bg-eurekka-preto">
          <img src={whiteLogo} className="navbar_logo" alt=""></img>

          <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
            {/* navItems */}
          </nav>
          <div className="ml-auto">
            {currentUser && (
              <ActiveButton title="Logout" onClick={handleLogout} />
            )}
          </div>
        </div>
        {pathname.includes('dashboard') && <NavbarDash />}
    </header>
    </section>
  );
};
export default Navbar;
